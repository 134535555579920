<template>
    <div
        v-if="showModal"
        class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
        <div class="bg-white p-4 rounded-lg max-w-md w-full">
            <h2 class="text-xl font-semibold mb-4 mx-auto text-center">
                Оберіть ресторан з якого бажаєте отримати замовлення:
            </h2>
            <ul>
                <li
                    v-for="restaurant in restaurants"
                    :key="restaurant.id"
                    @click="selectRestaurant(restaurant)"
                >
                    <div
                        class="border my-5 py-5 px-3 rounded-xl cursor-pointer hover:bg-gray-100 transition duration-200 ease-in-out"
                    >
                        <h3 class="text-md font-medium">
                            Колхеті Хінкальна на {{ restaurant.address }}{{ restaurant.id }}
                        </h3>
                        <p class="text-gray-400 my-2">
                            Телефон: {{ restaurant.phone }}
                        </p>
                        <div class="flex items-center">
                            <span
                                :class="{
                                    'bg-green-500': isOpen,
                                    'bg-red-500': !isOpen,
                                }"
                                class="w-3 h-3 rounded-full inline-block mr-2"
                            ></span>
                            <p>Відкрито: {{ restaurant.open_restaurant }}</p>
                            
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { usePage } from "@inertiajs/vue3";

const showModal = ref(true);
const { props } = usePage();
const restaurants = props.restaurants;

const selectRestaurant = (restaurant) => {
    localStorage.setItem("selectedRestaurant", JSON.stringify(restaurant));
    localStorage.setItem("restaurantSelected", "true");
    showModal.value = false;

    // Оповещаем о том, что ресторан был выбран
    const event = new CustomEvent("restaurant-selected", {
        detail: restaurant,
    });
    window.dispatchEvent(event);
};

onMounted(() => {
    const restaurantSelected = localStorage.getItem("restaurantSelected");
    if (!restaurantSelected) {
        showModal.value = true;
    } else {
        showModal.value = false;
    }
});

const isOpen = computed(() => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    // Ресторан открыт с 10:00 до 22:00
    return currentHour >= 10 && currentHour < 22;
});
</script>

<style scoped></style>
